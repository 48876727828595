document.addEventListener('DOMContentLoaded', () => {
	
	if (document.querySelector('.reviews')) {
		
		const reviewButtons = document.querySelectorAll('.reviews__item-info-button')
		
		for (let reviewButton of reviewButtons) {
			
			let reviewTxt = reviewButton.closest('.reviews__item-info').querySelector('.reviews__item-info-txt')
			if (reviewTxt.scrollHeight < 75) reviewButton.remove()
			
			reviewButton.addEventListener('click', function () {
				
				if (!reviewButton.classList.contains('active')) {
					reviewTxt.style['-webkit-line-clamp'] = 'initial';
					this.textContent = 'Свернуть'
				} else {
					reviewTxt.style['-webkit-line-clamp'] = '3';
					this.textContent = 'Посмотрите...'
				}
				
				this.classList.toggle('active')
			})
		}
	}
	
	accordeon(document.querySelectorAll('.faq__item'));
	accordeon(document.querySelectorAll('.footer__item-section'));
	
	function accordeon(items) {
		
		for (let item of items) {
			
			item.addEventListener('click', function () {
				
				this.classList.toggle('active');
				this.classList.contains('active') ?
					this.lastElementChild.style.height =
					this.lastElementChild.scrollHeight + 'px' :
					this.lastElementChild.style.height = '0px';
			})
		}
	}
	
	if (document.querySelector('[data-nav]')) {
		
		const nav = document.querySelector('[data-nav]')
		const menuBtn = document.querySelector('[data-nav-btn]')
		const navItems = document.querySelectorAll('[data-nav-item]')
		
		for (let navItem of navItems) {
			
			navItem.addEventListener('click', function (e) {
				e.stopPropagation()
				const navItemActive = document.querySelector('[data-nav-item].active')
				navItem.classList.add('active')
				navItemActive?.classList.remove('active')
			})
		}
		
		menuBtn.addEventListener('click', (e) => {
			e.stopPropagation()
			menuBtn.classList.toggle('animate')
			nav.classList.toggle('active')
		})
		
		document.addEventListener('click', function (e) {
			const navItemActive = document.querySelector('[data-nav-item].active')
			
			if (!e.target.classList.contains('item__title') && navItemActive) {
				navItemActive.classList.remove('active')
			}
			
			if (nav.classList.contains('active')) {
				menuBtn.classList.remove('animate')
				nav.classList.remove('active')
			}
		})
	}
	
	
	// Sliders
	
	if (document.querySelector('.calc__slider')) {

		const sliderCalc = new Swiper('.calc__slider', {
			navigation: {
				nextEl: '.reviews__slider-button-next',
				prevEl: '.reviews__slider-button-prev',
			},

			breakpoints: {

				320: {
					slidesPerView: 1.2,
					spaceBetween: 15
				},
				576: {
					slidesPerView: 2.2,
					spaceBetween: 18
				},
				991: {
					slidesPerView: 3,
					spaceBetween: 36
				}
			}
		})
	}
	
	
	if (document.querySelector('.projects__slider')) {
		
		const sections = document.querySelectorAll('.projects__slider')
		
		for (let section of sections) {
			const sectionSlides = section.querySelectorAll('.projects__content')
			let currentSlide = 1
			for (let sectionSlide of sectionSlides) {
				sectionSlide.querySelector('.projects__info-pagination-val')
					.textContent = `${currentSlide++}/${sectionSlides.length}`
			}
		}
		
		const sliderProjectsButtons = new Swiper(".projects__slider-buttons", {
			slidesPerView: 'auto',
			spaceBetween: 15,
			freeMode: true
		})
		
		const sliderProjects = new Swiper(".projects__slider", {
			slidesPerView: 1,
			spaceBetween: 15
		})
		
		const projectsSliders = document.querySelectorAll('.projects__content')
		
		for (let projectsSlider of projectsSliders) {
			
			const minis = projectsSlider.querySelector('.projects__slider-minis')
			const photos = projectsSlider.querySelector('.projects__slider-photos')
			const slidesPerView = minis.querySelectorAll('img').length
			
			const sliderProjectsMinis = new Swiper(minis, {
				spaceBetween: 15,
				slidesPerView,
				freeMode: true,
				watchSlidesProgress: true
			});
			
			const sliderProjectsPhotos = new Swiper(photos, {
				slidesPerView: 1,
				effect: 'fade',
				thumbs: {
					swiper: sliderProjectsMinis
				}
			})
		}
	}
	
	
	
	if (document.querySelector('.types__slider')) {
		
		const sliderTypes = new Swiper(".types__slider", {
			
			breakpoints: {
				320: {
					slidesPerView: 1.1,
					spaceBetween: 10
				},
				576: {
					slidesPerView: 2.2,
					spaceBetween: 15
				},
				991: {
					slidesPerView: 3.2,
					spaceBetween: 15
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 20
				}
			}
		})
	}
	
	if (document.querySelector('.reviews__slider')) {
		
		const sliderTypes = new Swiper(".reviews__slider", {
			
			breakpoints: {
				320: {
					slidesPerView: 1.1,
					spaceBetween: 10
				},
				576: {
					slidesPerView: 2.1,
					spaceBetween: 15
				},
				991: {
					slidesPerView: 3,
					spaceBetween: 20
				}
			}
		})
	}
	
	
	if (document.querySelector('.docs__slider')) {
		
		const sliderTypes = new Swiper(".docs__slider", {
			
			breakpoints: {
				320: {
					slidesPerView: 1.2,
					spaceBetween: 10
				},
				425: {
					slidesPerView: 2.2,
					spaceBetween: 10
				},
				576: {
					slidesPerView: 3.2,
					spaceBetween: 15
				},
				991: {
					slidesPerView: 4,
					spaceBetween: 20
				}
			}
		})
	}
	
	if (document.querySelector('.discount__slider')) {
		
		const sliderTypes = new Swiper(".discount__slider", {
			
			breakpoints: {
				320: {
					slidesPerView: 3.5,
					spaceBetween: 30
				},
				576: {
					slidesPerView: 3.5,
					spaceBetween: 50
				},
				768: {
					slidesPerView: 4.5,
					spaceBetween: 50
				},
				991: {
					slidesPerView: 6,
					spaceBetween: 80
				}
			}
		})
	}
	
})
